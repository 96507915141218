:root {

  --color-box-shadow-primary: 0 14px 28px rgb(38 35 35 / 55%), 0 10px 10px #111010;
  --color-box-shadow-content: 0 1px 3px 1px #d3740042;
  --color-box-shadow-input: 0 0 4px #cccccc;
  --color-box-shadow-input-focus: 0 1px 6px darkorange;

  --color-background-primary: darkorange;
  --color-background-badge: darkorange;
  --color-background-body: #2b2b2d;
  --color-background-button: #db6700;
  --color-background-alert: #ca4e4e;
  --color-background-poper: linear-gradient(to right, #0d0d0d 0%, #363636 100%);
  --color-background-box-part: linear-gradient(to right, #0d0d0d 0%, #363636 100%);
  --color-background-link-hover: linear-gradient(to right, #0d0d0d 0%, #363636 100%);
  --color-background-link-hover-no-mobile: rgba(0, 0, 0, 0.32);
  --color-background-input-text: #0d0d0d;
  --color-background-dropdown: linear-gradient(to right, #0f0f0f 0%, #434242 100%);
  --color-background-nabvar: rgba(33, 37, 41, 1);
  --color-background-card-chat: rgba(57, 192, 237, .2);
  --color-background-card-chat-answer: rgba(166, 194, 203, 0.2);

  --color-border-primary: darkorange;
  --color-border-button: #db6700;
  --color-border-content: #343a40;
  --color-border-content-primary: #343a40;
  --color-border-input-focus: #2f2e2e;

  --color-primary: darkorange;
  --color-alert: #ffffff;
  --color-link-hover-span: whitesmoke;
  --color-text: black;
  --color-a-text: #f5c500;
  --color-link: #f5c500;
  --color-link-hover: #efda2c;
  --color-content: #e5e5e5;
  --color-dropdown: whitesmoke;
  --color-navbar: black !important;
  --color-fa-icon: whitesmoke;
  --color-button-fa-icon: whitesmoke !important;
  --color-popper: black;
  --color-pagination-text: whitesmoke;

}

.bg-body {
  background-color: var(--color-background-badge) !important;
}

.text-white {
  color: var(--color-text) !important;
}

.popper-content {
  color: var(--color-popper);
}

.brand {
  color: var(--color-navbar);
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5 {
  color: var(--color-primary);
}

.box {
  padding: 60px 0;
}

button > fa-icon {
  color: var(--color-button-fa-icon);
}

.box-part {
  background: var(--color-background-box-part);
  box-shadow: var(--color-box-shadow-primary);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border-radius: 15px;
  padding: 40px 25px;
}


/** Buttons */

.btn-primary, .btn-info, .btn.disabled, .btn:disabled {
  margin: auto;
  background-color: var(--color-background-button) !important;
  border-color: var(--color-border-button) !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:target,
.btn-primary:active:focus {
  margin: auto;
  background-color: var(--color-background-primary) !important;
  border-color: var(--color-border-primary) !important;
}

.btn-info:hover,
.btn-info:active,
.btn-info:focus,
.btn-info:target,
.btn-info:active:focus {
  margin: auto;
  background-color: var(--color-background-primary);
  border-color: var(--color-border-primary);
}

body {
  background-color: var(--color-background-body);
}


.card {
  background: var(--color-background-box-part);
  box-shadow: var(--color-box-shadow-primary);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.card-body-chat {
  border-radius: 12px;
  background-color: var(--color-background-card-chat);
  word-break: break-all;
}

.card-body-chat-answer {
  border-radius: 12px;
  background-color: var(--color-background-card-chat-answer);
  word-break: break-all;
}


.content {
  font-style: italic;
  margin: 20px 0;
  text-align: center;
  color: var(--color-content);
  border-style: solid;
  border-width: thin;
  box-shadow: var(--color-box-shadow-content);
  border-color: #343a40;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.crop {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}

.file-uploader {
  border-radius: 20px;
  margin-top: 24px;
  min-height: 45px;
  min-width: 45px;
  background-color: var(--color-background-button);
  border-color: var(--color-border-button);
  display: inline-block;
  padding: 12px 18px;
  cursor: pointer;
}

.file-uploader:hover {
  background-color: var(--color-background-primary);
  border-color: var(--color-border-primary);
}

/** Inputs */

input {
  box-shadow: var(--color-box-shadow-input);
  border-left: none;
}

.input-group-text {
  background-color: var(--color-background-input-text);
  border: none;
  box-shadow: var(--color-box-shadow-input);
  color: var(--color-primary);
}

input[type="text"]:focus {
  outline: none !important;
  border: none var(--color-border-input-focus);
  box-shadow: var(--color-box-shadow-input-focus);
}

input[type="number"]:focus {
  outline: none !important;
  border: none var(--color-border-input-focus);
  box-shadow: var(--color-box-shadow-input-focus);
}

input[type="password"]:focus {
  outline: none !important;
  border: none var(--color-border-input-focus);
  box-shadow: var(--color-box-shadow-input-focus);
}

input[type="email"]:focus {
  outline: none !important;
  border: none var(--color-border-input-focus);
  box-shadow: var(--color-box-shadow-input-focus);
}

input[type="textarea"] {
  outline: none !important;
  border: none var(--color-border-input-focus);
  box-shadow: var(--color-box-shadow-input-focus);
}

textarea:focus {
  outline: none !important;
  border-style: none;
  border-color: var(--color-border-input-focus);
  box-shadow: var(--color-box-shadow-input-focus);
}

label {
  display: block;
  text-align: left;
  color: var(--color-primary);
  font-size: 16px;
}

.pointer-base {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: var(--color-a-text) !important;
}

.pointer:hover > fa-icon {
  color: var(--color-a-text);
}

textarea {
  height: 120px;
}

textarea.form-control:focus {
  outline: none !important;
  border-style: none;
  border-color: var(--color-border-input-focus);
  box-shadow: var(--color-box-shadow-input-focus);
}

select {
  box-shadow: var(--color-box-shadow-input);
  border-left: none;
  height: 40px;
  font-size: 16px;
}

select:focus {
  outline: none !important;
  border-style: none;
  border-color: var(--color-border-input-focus);
  box-shadow: var(--color-box-shadow-input-focus);
}

.img-banner {
  max-height: 50px;
}

.text-darkorange {
  color: var(--color-primary)
}

a > fa-icon {
  color: var(--color-primary)
}

fa-icon {
  color: var(--color-fa-icon);
}


.alert-danger {
  background-color: var(--color-background-alert);
  color: var(--color-alert);
  border: none;
}

.login-icon {
  color: var(--color-primary);
}

.link:hover {
  background-color: var(--color-background-link-hover-no-mobile);
  color: var(--color-primary)
}

.link:hover span {
  color: var(--color-primary)
}

.overflow {
  text-overflow: ellipsis;
}

select:disabled {
  opacity: 0.7;
}

.page-item.disabled .page-link {
  background-color: var(--color-background-button);
  color: var(--color-pagination-text);
}

.page-link:hover, .page-link.active {
  background-color: var(--color-background-primary);
  color: var(--color-pagination-text);
}

.page-link {
  margin-top: 25px;
  cursor: pointer;
  background-color: var(--color-background-button);
  color: var(--color-pagination-text);
  border: 1px solid var(--color-background-link-hover);
}

.active > .page-link {
  background-color: var(--color-background-primary);
  color: var(--color-pagination-text);
}


/**  Buttons for mobile **/
@media screen and (max-width: 576px) {
  span:before {
    content: attr(data-label);
    font-weight: bold;
    color: var(--color-primary);
    margin-right: auto;
    display: inline-block;
  }

  .btn-primary,
  .btn-info,
  .btn-danger {
    width: 100px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 3px;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  button.btn:hover,
  button.btn:active,
  button.btn:focus,
  button.btn:target,
  button.btn:active:focus {
    width: 75%;
  }

  .btn-info,
  .btn-primary,
  .btn-danger {
    width: 75%;
  }

  .link:hover {
    background: var(--color-background-link-hover);
  }

  .link:hover span {
    color: var(--color-link-hover-span);
  }
}

/**  End Buttons for mobile **/
